import React, { useEffect, useRef, useState } from "react";
import styles from "./styles/Navbar.module.css";
import {
  BiPowerOff,
  BiUser,
  BiLayout,
  BiListMinus,
  BiSolidCity,
  BiPhotoAlbum,
  BiImageAdd,
  BiHighlight,
  BiCalendar,
  BiSelection,
  BiUserPlus,
  BiCart,
  BiMoney,
  BiGroup,
  BiCertification,
  BiCalendarEvent,
  BiHome,
  BiCreditCard,
  BiListUl,
  BiSelectMultiple,
  BiError,
  BiAlbum,
  BiStopwatch,
} from "react-icons/bi";
import axios from "axios";
import LoginPopUp from "../pages/LoginPopUp";
import LoginPopUpStyle from "../pages/styles/LoginPopUp.module.css";
import { Alert } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import JWT from "expo-jwt";
import { SupportedAlgorithms } from "expo-jwt/dist/types/algorithms";

const loadDataLegality = async () => {
  try {
    let jwtToken = JWT.encode(
      {
        client_id: process.env.REACT_APP_ID,
        email_user: JSON.parse(localStorage.getItem("profile")).email,
        timestamp: new Date().getTime(),
      },
      process.env.REACT_APP_BACKEND_KEY,
      { algorithm: SupportedAlgorithms.HS256 }
    );
    let orgs = await axios.get(
      process.env.REACT_APP_BACKEND_URL + "/api/admin/legality-datas",
      {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("access_token"),
          "x-api-key": jwtToken,
          time: new Date().getTime(),
        },
      }
    );
    return {
      data: orgs.data,
      status: orgs.status,
    };
  } catch (error) {
    console.log(error);
    if (error.response === undefined) {
      return {
        data: { data: [error.message] },
        status: 500,
      };
    } else {
      return {
        data: error.response,
        status: error.response.status,
      };
    }
  }
};

const loadRefunds = async () => {
  // refund_datas (out key)
  try {
    let jwtToken = JWT.encode(
      {
        client_id: process.env.REACT_APP_ID,
        email_user: JSON.parse(localStorage.getItem("profile")).email,
        timestamp: new Date().getTime(),
      },
      process.env.REACT_APP_BACKEND_KEY,
      { algorithm: SupportedAlgorithms.HS256 }
    );
    let data = await axios.get(
      process.env.REACT_APP_BACKEND_URL + "/api/admin/refunds",
      {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("access_token"),
          "x-api-key": jwtToken,
          time: new Date().getTime(),
        },
      }
    );
    return {
      data: data.data,
      status: data.status,
    };
  } catch (error) {
    console.log(error);
    if (error.response === undefined) {
      return {
        data: { data: [error.message] },
        status: 500,
      };
    } else {
      return {
        data: error.response,
        status: error.response.status,
      };
    }
  }
};

const loadDataWd = async () => {
  // Out key (withdraws)
  try {
    let jwtToken = JWT.encode(
      {
        client_id: process.env.REACT_APP_ID,
        email_user: JSON.parse(localStorage.getItem("profile")).email,
        timestamp: new Date().getTime(),
      },
      process.env.REACT_APP_BACKEND_KEY,
      { algorithm: SupportedAlgorithms.HS256 }
    );
    let wds = await axios.get(
      process.env.REACT_APP_BACKEND_URL + "/api/admin/withdraws",
      {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("access_token"),
          "x-api-key": jwtToken,
          time: new Date().getTime(),
        },
      }
    );
    return {
      data: wds.data,
      status: wds.status,
    };
  } catch (error) {
    console.log(error);
    if (error.response === undefined) {
      return {
        data: { data: [error.message] },
        status: 500,
      };
    } else {
      return {
        data: error.response,
        status: error.response.status,
      };
    }
  }
};

const loadUnsentMails = async () => {
  try {
    let jwtToken = JWT.encode(
      {
        client_id: process.env.REACT_APP_ID,
        email_user: JSON.parse(localStorage.getItem("profile")).email,
        timestamp: new Date().getTime(),
      },
      process.env.REACT_APP_BACKEND_KEY,
      { algorithm: SupportedAlgorithms.HS256 }
    );
    let data = await axios.get(
      process.env.REACT_APP_BACKEND_URL + "/api/admin/mail-errors",
      {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("access_token"),
          "x-api-key": jwtToken,
        },
      }
    );
    return {
      data: data.data,
      status: data.status,
    };
  } catch (error) {
    if (error.response === undefined) {
      return {
        data: { data: [error.message] },
        status: 500,
      };
    } else {
      return {
        data: error.response,
        status: error.response.status,
      };
    }
  }
};

const Navbar = ({
  children,
  active,
  profileIcon,
  setProfileIcon = () => {},
  loginState,
  setLoginState = () => {},
}) => {
  const [profileMenu, setProfileMenuState] = useState(false);
  const [notifyRefund, setNotifyRefund] = useState(0);
  const [notifyWd, setNotifyWd] = useState(0);
  const [notifyLegality, setNotifyLegality] = useState(0);
  const [notifyUnsetMails, setNotifyUnMails] = useState(0);
  const [errorState, setErrorState] = useState(false);
  const [firstLoad, setFirstLoad] = useState(false);
  const sidebar = useRef();
  const content = useRef();
  const navigate = useNavigate();

  const resizeContent = () => {
    if (sidebar.current) {
      let sidebarWd = sidebar.current.clientWidth;
      let height = window.innerHeight - 72;
      content.current.style.width = `calc(100% - ${sidebarWd}px)`;
      content.current.style.maxHeight = height + "px";
      content.current.style.overflowY = "auto";
    }
  };

  const resizeSidebar = () => {
    if (sidebar.current) {
      let height = window.innerHeight - 72;
      sidebar.current.style.maxHeight = height + "px";
    }
  };

  useEffect(() => {
    if (loginState === 1) {
      resizeContent();
      resizeSidebar();
      document.addEventListener("click", (evt) => {
        let target = document.getElementById("profileBtn");
        let els = evt.target;
        if (target != els) {
          setProfileMenuState(false);
        }
      });
      window.addEventListener("resize", () => {
        console.log("resize");
        resizeContent();
        resizeSidebar();
      });

      loadDataLegality().then((res) => {
        if (res.status === 200) {
          setNotifyLegality(res.data.unverified.length);
        } else if (res.status === 401) {
          setLoginState(0);
        } else if (res.status === 405) {
          setLoginState(3);
        } else if (res.status !== 404) {
          setErrorState(true);
        }
      });

      loadDataWd().then((res) => {
        if (res.status === 200) {
          let notify = 0;
          res.data.withdraws.forEach((data) => {
            if (data.status == 0) {
              notify += 1;
            }
          });
          setNotifyWd(notify);
        } else if (res.status === 401) {
          setLoginState(0);
        } else if (res.status === 405) {
          setLoginState(3);
        } else if (res.status !== 404) {
          setErrorState(true);
        }
      });

      loadRefunds().then((res) => {
        if (res.status === 200) {
          let numNotify = 0;
          res.data.refund_datas.forEach((data) => {
            if (!data.approve_admin) {
              numNotify += 1;
            }
          });
          setNotifyRefund(numNotify);
        } else if (res.status === 401) {
          setLoginState(0);
        } else if (res.status === 405) {
          setLoginState(3);
        } else if (res.status !== 404) {
          setErrorState(true);
        }
      });

      loadUnsentMails().then((res) => {
        if (res.status === 200) {
          setNotifyUnMails(res.data.data.length);
        } else if (res.status === 401) {
          setLoginState(0);
        } else if (res.status === 405) {
          setLoginState(3);
        } else if (res.status !== 404) {
          setErrorState(true);
        }
      });
    }
  }, [loginState]);

  useEffect(() => {
    if (
      (loginState === 0 || loginState === 2) &&
      window.location.pathname === "/"
    ) {
      navigate("/login");
    }
  }, [loginState]);

  return (
    <>
      <nav
        style={
          window.location.pathname === "/login" ||
          (window.location.pathname === "/" &&
            (loginState === 0 || loginState === 2))
            ? { display: "none" }
            : { top: 0, zIndex: 5 }
        }
        className="navbar navbar-expand-lg bg-body-tertiary position-fixed w-100"
      >
        <div className="container-fluid ps-4 pe-4">
          <a className="navbar-brand fs-6" href="#">
            <img src="/images/logo.png" className={`${styles.Logo}`} /> &nbsp;
            &nbsp;| &nbsp; Admin
          </a>
          <button
            className={`${styles.NavbarToggler} navbar-toggler`}
            type="button"
            aria-expanded="false"
            aria-label="Toggle navigation"
            data-bs-toggle="offcanvas"
            data-bs-target="#offcanvasResponsive"
            aria-controls="offcanvasResponsive"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div
            className={`collapse navbar-collapse ${styles.NavbarCollapse}`}
            id="navbarNavDropdown"
          >
            <ul className="navbar-nav ms-auto">
              <li className="nav-item">
                <a
                  className="nav-link active"
                  aria-current="page"
                  href="#"
                  onClick={() => setProfileMenuState(!profileMenu)}
                >
                  <div className={`rounded-circle ${styles.IconProfile}`}>
                    <img
                      id="profileBtn"
                      src={profileIcon}
                      className="rounded-3"
                      alt=""
                      srcset=""
                    />
                  </div>
                </a>
              </li>
            </ul>
          </div>

          {profileMenu ? (
            <div className={`${styles.Profile}`}>
              {/* <a href="/profile" className="text-decoration-none text-dark">
								<div
									className={`${styles.ProfileItem} ${
										active === "profile" ? styles.ItemSideNavActive : ""
									}`}
								>
									<BiUser />
									Profile
								</div>
							</a> */}
              <a href="/logout">
                <div
                  className={`${styles.ProfileItem} bg-danger rounded-pill text-white`}
                >
                  <BiPowerOff />
                  Logout
                </div>
              </a>
            </div>
          ) : (
            <></>
          )}

          <div
            className={`${styles.OffCanvas} offcanvas-lg offcanvas-end`}
            tabindex="-1"
            id="offcanvasResponsive"
            aria-labelledby="offcanvasResponsiveLabel"
          >
            <div className="offcanvas-header">
              <h5 className="offcanvas-title" id="offcanvasResponsiveLabel">
                Navigasi
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="offcanvas"
                data-bs-target="#offcanvasResponsive"
                aria-label="Close"
              ></button>
            </div>
            <div className={`${styles.OffCanvasBody} offcanvas-body`}>
              <div className={`rounded-circle ${styles.ItemSideNav}`}>
                <div className={`${styles.IconProfile} `}>
                  <img
                    src={profileIcon}
                    className="rounded-3"
                    alt=""
                    srcset=""
                  />
                </div>

                <div className={`ms-4 mt-auto mb-auto`}>Syaifudin Ramadhan</div>
              </div>
              <hr className="mb-3" />
              <div className={`${styles.ItemSideNav} p-0 bg-white mt-4 mb-2`}>
                <div className={`fs-6 text-secondary`}>Basic Menu</div>
              </div>
              <a href="/" className="text-decoration-none text-black">
                <div
                  className={`${styles.ItemSideNav} ${
                    active === "dashboard" ? styles.ItemSideNavActive : ""
                  }`}
                >
                  <BiHome />
                  Dashboard
                </div>
              </a>
              <a href="/categories" className="text-decoration-none text-black">
                <div
                  className={`${styles.ItemSideNav} ${
                    active === "categories" ? styles.ItemSideNavActive : ""
                  }`}
                >
                  <BiLayout />
                  Event Categories
                </div>
              </a>
              <a href="/topics" className="text-decoration-none text-black">
                <div
                  className={`${styles.ItemSideNav} ${
                    active === "topics" ? styles.ItemSideNavActive : ""
                  }`}
                >
                  <BiListMinus />
                  Event Topics
                </div>
              </a>
              <a
                href="/activity-topics"
                className="text-decoration-none text-black"
              >
                <div
                  className={`${styles.ItemSideNav} ${
                    active === "activity-topics" ? styles.ItemSideNavActive : ""
                  }`}
                >
                  <BiListUl />
                  Activity Topics
                </div>
              </a>
              <a href="/org-tyoes" className="text-decoration-none text-black">
                <div
                  className={`${styles.ItemSideNav} ${
                    active === "org-tyoes" ? styles.ItemSideNavActive : ""
                  }`}
                >
                  <BiListUl />
                  Organization Types
                </div>
              </a>
              <a href="/cities" className="text-decoration-none text-black">
                <div
                  className={`${styles.ItemSideNav} ${
                    active === "cities" ? styles.ItemSideNavActive : ""
                  }`}
                >
                  <BiSolidCity />
                  Cities
                </div>
              </a>
              <a
                href="/front-banners"
                className="text-decoration-none text-black"
              >
                <div
                  className={`${styles.ItemSideNav} ${
                    active === "front-banners" ? styles.ItemSideNavActive : ""
                  }`}
                >
                  <BiImageAdd />
                  Front Banners
                </div>
              </a>
              <a
                href="/front-banners-large"
                className="text-decoration-none text-black"
              >
                <div
                  className={`${styles.ItemSideNav} ${
                    active === "front-banners-large" ? styles.ItemSideNavActive : ""
                  }`}
                >
                  <BiImageAdd />
                  Front Banners Large
                </div>
              </a>
              <a href="/spotlights" className="text-decoration-none text-black">
                <div
                  className={`${styles.ItemSideNav} ${
                    active === "spotlights" ? styles.ItemSideNavActive : ""
                  }`}
                >
                  <BiHighlight />
                  Event Spotlights
                </div>
              </a>
              <a
                href="/special-days"
                className="text-decoration-none text-black"
              >
                <div
                  className={`${styles.ItemSideNav} ${
                    active === "special-days" ? styles.ItemSideNavActive : ""
                  }`}
                >
                  <BiCalendar />
                  Special Day Events
                </div>
              </a>
              <a href="/selecteds" className="text-decoration-none text-black">
                <div
                  className={`${styles.ItemSideNav} ${
                    active === "selecteds" ? styles.ItemSideNavActive : ""
                  }`}
                >
                  <BiSelection />
                  Selected Events
                </div>
              </a>
              <a
                href="/selected-activities"
                className="text-decoration-none text-black"
              >
                <div
                  className={`${styles.ItemSideNav} ${
                    active === "selected-activities"
                      ? styles.ItemSideNavActive
                      : ""
                  }`}
                >
                  <BiSelectMultiple />
                  Selected Activities
                </div>
              </a>
              <a
                href="/commision-setup"
                className="text-decoration-none text-black"
              >
                <div
                  className={`${styles.ItemSideNav} ${
                    active === "commision-setup" ? styles.ItemSideNavActive : ""
                  }`}
                >
                  <BiSelection />
                  Commision Setup
                </div>
              </a>
              <hr />
              <div className={`${styles.ItemSideNav} p-0 bg-white mt-4 mb-2`}>
                <div className={`fs-6 text-secondary`}>Manage User</div>
              </div>
              <a href="/users" className="text-decoration-none text-black">
                <div
                  className={`${styles.ItemSideNav} ${
                    active === "users" ? styles.ItemSideNavActive : ""
                  }`}
                >
                  <BiUser />
                  Users
                </div>
              </a>
              <a href="/admins" className="text-decoration-none text-black">
                <div
                  className={`${styles.ItemSideNav} ${
                    active === "admins" ? styles.ItemSideNavActive : ""
                  }`}
                >
                  <BiUserPlus />
                  Admins
                </div>
              </a>
              <a href="/purchases" className="text-decoration-none text-black">
                <div
                  className={`${styles.ItemSideNav} ${
                    active === "purchases" ? styles.ItemSideNavActive : ""
                  } position-relative`}
                >
                  <BiCart />
                  Purchases
                </div>
              </a>
              <a
                href="/refund-setup"
                className="text-decoration-none text-black"
              >
                <div
                  className={`${styles.ItemSideNav} ${
                    active === "refund-setup" ? styles.ItemSideNavActive : ""
                  } position-relative`}
                >
                  <BiAlbum />
                  Refund Setup
                </div>
              </a>
              <a
                href="/refund-cancel-manager"
                className="text-decoration-none text-black"
              >
                <div
                  className={`${styles.ItemSideNav} ${
                    active === "refund-cancel-manager"
                      ? styles.ItemSideNavActive
                      : ""
                  } position-relative`}
                >
                  <BiError />
                  Refund & Cancel
                  {notifyRefund > 0 ? (
                    <span class="badge ms-2 bg-danger">
                      {notifyRefund}
                      {/* <span class="visually-hidden">Waiting legality data</span> */}
                    </span>
                  ) : (
                    <></>
                  )}
                </div>
              </a>
              <a
                href="/unsent-mails"
                className="text-decoration-none text-black"
              >
                <div
                  className={`${styles.ItemSideNav} ${
                    active === "unsent-mails" ? styles.ItemSideNavActive : ""
                  } position-relative`}
                >
                  <BiStopwatch />
                  Unsent Mails
                  {notifyUnsetMails > 0 ? (
                    <span class="badge ms-2 bg-danger">
                      {notifyUnsetMails}
                      {/* <span class="visually-hidden">Waiting legality data</span> */}
                    </span>
                  ) : (
                    <></>
                  )}
                </div>
              </a>
              {/* <a href="/payments" className="text-decoration-none text-black">
								<div
									className={`${styles.ItemSideNav} ${
										active === "payments" ? styles.ItemSideNavActive : ""
									}`}
								>
									<BiMoney />
									Payments
								</div>
							</a> */}
              <hr />
              <div className={`${styles.ItemSideNav} p-0 bg-white mt-4 mb-2`}>
                <div className={`fs-6 text-secondary`}>Manage Organization</div>
              </div>
              <a
                href="/organizations"
                className="text-decoration-none text-black"
              >
                <div
                  className={`${styles.ItemSideNav} ${
                    active === "organizations" ? styles.ItemSideNavActive : ""
                  }`}
                >
                  <BiGroup />
                  Organizations
                </div>
              </a>
              <a href="/legalities" className="text-decoration-none text-black">
                <div
                  className={`${styles.ItemSideNav} ${
                    active === "legalities" ? styles.ItemSideNavActive : ""
                  } position-relative`}
                >
                  <BiCertification />
                  Legality Data
                  {notifyLegality > 0 ? (
                    <span class="badge ms-2 bg-danger">
                      {notifyLegality}
                      {/* <span class="visually-hidden">Waiting legality data</span> */}
                    </span>
                  ) : (
                    <></>
                  )}
                </div>
              </a>
              <a href="/withdraws" className="text-decoration-none text-black">
                <div
                  className={`${styles.ItemSideNav} ${
                    active === "withdraws" ? styles.ItemSideNavActive : ""
                  } position-relative`}
                >
                  <BiCreditCard />
                  Withdraws
                  {notifyWd > 0 ? (
                    <span class="badge ms-2 bg-danger">
                      {notifyWd}
                      {/* <span class="visually-hidden">Waiting legality data</span> */}
                    </span>
                  ) : (
                    <></>
                  )}
                </div>
              </a>
              <hr />
              <div className={`${styles.ItemSideNav} p-0 bg-white mt-4 mb-2`}>
                <div className={`fs-6 text-secondary`}>Manage Event</div>
              </div>
              <a href="/events" className="text-decoration-none text-black">
                <div
                  className={`${styles.ItemSideNav} ${
                    active === "events" ? styles.ItemSideNavActive : ""
                  }`}
                >
                  <BiCalendarEvent />
                  Events
                </div>
              </a>
              <hr />
              {/* <a href="/profile" className="text-decoration-none text-black">
								<div
									className={`${styles.ItemSideNav} ${
										active === "profile" ? styles.ItemSideNavActive : ""
									}`}
								>
									<BiUser />
									Profile
								</div>
							</a> */}
              <a href="/logout" className="text-decoration-none text-black">
                <button className="btn btn-danger w-100 p-0 text-center d-flex">
                  <div className={`${styles.ItemSideNav} ms-auto me-auto`}>
                    <BiPowerOff />
                    Logout
                  </div>
                </button>
              </a>
            </div>
          </div>
        </div>
      </nav>
      <div
        style={
          window.location.pathname === "/login" ||
          (window.location.pathname === "/" &&
            (loginState === 0 || loginState === 2))
            ? { display: "none" }
            : {}
        }
        className={`${styles.MainContainer}`}
      >
        <div ref={sidebar} className={`${styles.Sidebar}`}>
          <div className={`${styles.ItemSideNav} p-0 bg-white mt-2 mb-2`}>
            <div className={`fs-6 text-secondary`}>Basic Menu</div>
          </div>
          <a href="/" className="text-decoration-none text-black">
            <div
              className={`${styles.ItemSideNav} ${
                active === "dashboard" ? styles.ItemSideNavActive : ""
              }`}
            >
              <BiHome />
              Dashboard
            </div>
          </a>
          <a href="/categories" className="text-decoration-none text-black">
            <div
              className={`${styles.ItemSideNav} ${
                active === "categories" ? styles.ItemSideNavActive : ""
              }`}
            >
              <BiLayout />
              Event Categories
            </div>
          </a>
          <a href="/topics" className="text-decoration-none text-black">
            <div
              className={`${styles.ItemSideNav} ${
                active === "topics" ? styles.ItemSideNavActive : ""
              }`}
            >
              <BiListMinus />
              Event Topics
            </div>
          </a>
          <a
            href="/activity-topics"
            className="text-decoration-none text-black"
          >
            <div
              className={`${styles.ItemSideNav} ${
                active === "activity-topics" ? styles.ItemSideNavActive : ""
              }`}
            >
              <BiListUl />
              Activity Topics
            </div>
          </a>
          <a href="/org-types" className="text-decoration-none text-black">
            <div
              className={`${styles.ItemSideNav} ${
                active === "org-types" ? styles.ItemSideNavActive : ""
              }`}
            >
              <BiListUl />
              Organization Types
            </div>
          </a>
          <a href="/cities" className="text-decoration-none text-black">
            <div
              className={`${styles.ItemSideNav} ${
                active === "cities" ? styles.ItemSideNavActive : ""
              }`}
            >
              <BiSolidCity />
              Cities
            </div>
          </a>
          <a href="/front-banners" className="text-decoration-none text-black">
            <div
              className={`${styles.ItemSideNav} ${
                active === "front-banners" ? styles.ItemSideNavActive : ""
              }`}
            >
              <BiImageAdd />
              Front Banners
            </div>
          </a>
          <a href="/front-banners-large" className="text-decoration-none text-black">
            <div
              className={`${styles.ItemSideNav} ${
                active === "front-banners-large" ? styles.ItemSideNavActive : ""
              }`}
            >
              <BiImageAdd />
              Front Banners Large
            </div>
          </a>
          <a href="/spotlights" className="text-decoration-none text-black">
            <div
              className={`${styles.ItemSideNav} ${
                active === "spotlights" ? styles.ItemSideNavActive : ""
              }`}
            >
              <BiHighlight />
              Event Spotlights
            </div>
          </a>
          <a href="/special-days" className="text-decoration-none text-black">
            <div
              className={`${styles.ItemSideNav} ${
                active === "special-days" ? styles.ItemSideNavActive : ""
              }`}
            >
              <BiCalendar />
              Special Day Events
            </div>
          </a>
          <a href="/selecteds" className="text-decoration-none text-black">
            <div
              className={`${styles.ItemSideNav} ${
                active === "selecteds" ? styles.ItemSideNavActive : ""
              }`}
            >
              <BiSelection />
              Selected Events
            </div>
          </a>
          <a
            href="/selected-activities"
            className="text-decoration-none text-black"
          >
            <div
              className={`${styles.ItemSideNav} ${
                active === "selected-activities" ? styles.ItemSideNavActive : ""
              }`}
            >
              <BiSelectMultiple />
              Selected Activities
            </div>
          </a>
          <a
            href="/commision-setup"
            className="text-decoration-none text-black"
          >
            <div
              className={`${styles.ItemSideNav} ${
                active === "commision-setup" ? styles.ItemSideNavActive : ""
              }`}
            >
              <BiSelection />
              Commision Setup
            </div>
          </a>
          <hr />
          <div className={`${styles.ItemSideNav} p-0 bg-white mt-4 mb-2`}>
            <div className={`fs-6 text-secondary`}>Manage User</div>
          </div>
          <a href="/users" className="text-decoration-none text-black">
            <div
              className={`${styles.ItemSideNav} ${
                active === "users" ? styles.ItemSideNavActive : ""
              }`}
            >
              <BiUser />
              Users
            </div>
          </a>
          <a href="/admins" className="text-decoration-none text-black">
            <div
              className={`${styles.ItemSideNav} ${
                active === "admins" ? styles.ItemSideNavActive : ""
              }`}
            >
              <BiUserPlus />
              Admins
            </div>
          </a>
          <a href="/purchases" className="text-decoration-none text-black">
            <div
              className={`${styles.ItemSideNav} ${
                active === "purchases" ? styles.ItemSideNavActive : ""
              } position-relative`}
            >
              <BiCart />
              Purchases
            </div>
          </a>
          <a href="/refund-setup" className="text-decoration-none text-black">
            <div
              className={`${styles.ItemSideNav} ${
                active === "refund-setup" ? styles.ItemSideNavActive : ""
              } position-relative`}
            >
              <BiAlbum />
              Refund Setup
            </div>
          </a>
          <a
            href="/refund-cancel-manager"
            className="text-decoration-none text-black"
          >
            <div
              className={`${styles.ItemSideNav} ${
                active === "refund-cancel-manager"
                  ? styles.ItemSideNavActive
                  : ""
              } position-relative`}
            >
              <BiError />
              Refund & Cancel
              {notifyRefund > 0 ? (
                <span class="badge ms-2 bg-danger">
                  {notifyRefund}
                  {/* <span class="visually-hidden">Waiting legality data</span> */}
                </span>
              ) : (
                <></>
              )}
            </div>
          </a>
          <a href="/unsent-mails" className="text-decoration-none text-black">
            <div
              className={`${styles.ItemSideNav} ${
                active === "unsent-mails" ? styles.ItemSideNavActive : ""
              } position-relative`}
            >
              <BiStopwatch />
              Unsent Mails
              {notifyUnsetMails > 0 ? (
                <span class="badge ms-2 bg-danger">
                  {notifyUnsetMails}
                  {/* <span class="visually-hidden">Waiting legality data</span> */}
                </span>
              ) : (
                <></>
              )}
            </div>
          </a>
          {/* <a href="/payments" className="text-decoration-none text-black">
						<div
							className={`${styles.ItemSideNav} ${
								active === "payments" ? styles.ItemSideNavActive : ""
							}`}
						>
							<BiMoney />
							Payments
						</div>
					</a> */}
          <hr />
          <div className={`${styles.ItemSideNav} p-0 bg-white mt-4 mb-2`}>
            <div className={`fs-6 text-secondary`}>Manage Organization</div>
          </div>
          <a href="/organizations" className="text-decoration-none text-black">
            <div
              className={`${styles.ItemSideNav} ${
                active === "organizations" ? styles.ItemSideNavActive : ""
              }`}
            >
              <BiGroup />
              Organizations
            </div>
          </a>
          <a href="/legalities" className="text-decoration-none text-black">
            <div
              className={`${styles.ItemSideNav} ${
                active === "legalities" ? styles.ItemSideNavActive : ""
              } position-relative`}
            >
              <BiCertification />
              Legality Data
              {notifyLegality > 0 ? (
                <span class="badge ms-2 bg-danger">
                  {notifyLegality}
                  {/* <span class="visually-hidden">Waiting legality data</span> */}
                </span>
              ) : (
                <></>
              )}
            </div>
          </a>
          <a href="/withdraws" className="text-decoration-none text-black">
            <div
              className={`${styles.ItemSideNav} ${
                active === "withdraws" ? styles.ItemSideNavActive : ""
              } position-relative`}
            >
              <BiCreditCard />
              Withdraws
              {notifyWd > 0 ? (
                <span class="badge ms-2 bg-danger">
                  {notifyWd}
                  {/* <span class="visually-hidden">Waiting legality data</span> */}
                </span>
              ) : (
                <></>
              )}
            </div>
          </a>
          <hr />
          <div className={`${styles.ItemSideNav} p-0 bg-white mt-4 mb-2`}>
            <div className={`fs-6 text-secondary`}>Manage Event</div>
          </div>
          <a href="/events" className="text-decoration-none text-black">
            <div
              className={`${styles.ItemSideNav} ${
                active === "events" ? styles.ItemSideNavActive : ""
              }`}
            >
              <BiCalendarEvent />
              Events
            </div>
          </a>
        </div>
        <div ref={content} className={`${styles.Content}`}>
          {(loginState === 0 || loginState === 2 || loginState === 3) &&
          window.location.pathname !== "/login" &&
          window.location.pathname !== "/" ? (
            <div
              className={`modal ${LoginPopUpStyle.PopUpLogin}`}
              aria-hidden="true"
              aria-labelledby="exampleModalToggleLabel"
              data-bs-backdrop="static"
              data-bs-keyboard="false"
            >
              <div
                className={`modal-dialog modal-dialog-centered ${LoginPopUp.PopUpLoginContent}`}
              >
                <div className="modal-content">
                  <div className="pt-3 ps-3 pe-3">
                    {loginState !== 3 ? (
                      <Alert variant="info">
                        Login session has ended. Please login again for continue
                        your activity
                      </Alert>
                    ) : (
                      <div className="mt-4"></div>
                    )}
                  </div>
                  <LoginPopUp
                    fnSetLoginState={setLoginState}
                    fnSetUserData={setProfileIcon}
                    loginState={loginState}
                  />
                </div>
              </div>
            </div>
          ) : (
            <></>
          )}
          {console.log(errorState)}
          {errorState ? (
            <div className={styles.ErrorPage}>
              <div>
                <BiError />
                <h5>Terjadi Kesalahan ! Silahkan Muat Ulang Halaman !</h5>
              </div>
            </div>
          ) : (
            <div style={loginState ? {} : { display: "none" }}>{children}</div>
          )}
        </div>
      </div>
    </>
  );
};

export default Navbar;
